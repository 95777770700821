import { Fragment, Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRouter';
import { ROUTER_NAME } from './const';
import PrivatePileSelectRouter from './PrivatePileSelectRouter';

const Login = lazy(() => import('../pages/U0_Login'));
const LoginStep2 = lazy(() => import('../pages/U0_Login/Step2'));
const PileCenterManager = lazy(() => import('../pages/U1_PileCenterManager'));
const TimeStudyInput = lazy(() => import('../pages/U2_TimeStudyInput'));
const CasingErection = lazy(() => import('../pages/U3_CasingErection'));
const CasingInstallation = lazy(() => import('../pages/U3_1CasingInstallation'));
const ShaftExcavation = lazy(() => import('../pages/U4_ShaftExcavation'));
const ExcavationCompletion = lazy(() => import('../pages/U5_ExcavationCompletion'));
const StabilizerManagement = lazy(() => import('../pages/U6_StabilizerManagement'));
const PrimarySlimeProcessing = lazy(() => import('../pages/U7_PrimarySlimeProcessing'));
const RebarInspection = lazy(() => import('../pages/U8_RebarInspection'));
const PileNoChange = lazy(() => import('../pages/U0_1PileNoChange'));
const SteelPileInspection = lazy(() => import('../pages/U9_SteelPileInspection'));
const HoleWallMeasurement = lazy(() => import('../pages/U10_HoleWallMeasurement'));
const HoleWallMeasurement1 = lazy(() => import('../pages/U10_HoleWallMeasurement/SubPage1'));
const SteelPileRebarManage = lazy(() => import('../pages/U11_SteelPileRebarManage'));
const SteelPileRebarManageAfter = lazy(() => import('../pages/U12_SteelPileRebarManageAfter'));
const TomeryErection = lazy(() => import('../pages/U13_ToremyErection'));
const U14ConcretePlacement = lazy(() => import('../pages/U14_ConcretePlacement'));
const U15ConcreteTest = lazy(() => import('../pages/U15_ConcreteTest'));

const U17PostInstallationInspection = lazy(() => import('../pages/U17_PostInstallationInspection'));
const U18HardnessTesting = lazy(() => import('../pages/U18_hardnessTesting'));
const U19RecordManagement = lazy(() => import('../pages/U19_RecordManagement'));
const U20Note = lazy(() => import('../pages/U20_Note'));

const U25StructuralColumn = lazy(() => import('../pages/U25_StructuralColumn'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));

function AppRouter() {
  const loader = <div className="example">Loading...</div>;
  return (
    <Fragment>
      <Suspense fallback={loader}>
        <BrowserRouter>
          <Routes>
            <Route path={ROUTER_NAME.U00} element={<Login />} />
            <Route path={ROUTER_NAME.U01} element={<PrivatePileSelectRouter />}>
              <Route path={ROUTER_NAME.U01} element={<LoginStep2 />} />
            </Route>

            <Route path={ROUTER_NAME.HOME} element={<PrivateRoute />}>
              <Route path={ROUTER_NAME.HOME} element={<Login />} />
            </Route>

            <Route path={ROUTER_NAME.U1} element={<PrivateRoute screenCode="U1" />}>
              <Route path={ROUTER_NAME.U1} element={<PileCenterManager />} />
            </Route>

            <Route path={ROUTER_NAME.U2} element={<PrivateRoute />}>
              <Route path={ROUTER_NAME.U2} element={<TimeStudyInput />} />
            </Route>

            <Route path={ROUTER_NAME.U3} element={<PrivateRoute screenCode="U3" />}>
              <Route path={ROUTER_NAME.U3} element={<CasingErection />} />
            </Route>

            <Route path={ROUTER_NAME.U3_1} element={<PrivateRoute screenCode="U3_1" />}>
              <Route path={ROUTER_NAME.U3_1} element={<CasingInstallation />} />
            </Route>

            <Route path={ROUTER_NAME.U02} element={<PrivatePileSelectRouter />}>
              <Route path={ROUTER_NAME.U02} element={<PileNoChange />} />
            </Route>

            <Route path={ROUTER_NAME.U4} element={<PrivateRoute screenCode="U4" />}>
              <Route path={ROUTER_NAME.U4} element={<ShaftExcavation />} />
            </Route>

            <Route path={ROUTER_NAME.U5} element={<PrivateRoute screenCode="U5" />}>
              <Route path={ROUTER_NAME.U5} element={<ExcavationCompletion />} />
            </Route>

            <Route path={ROUTER_NAME.U6} element={<PrivateRoute screenCode="U6" />}>
              <Route path={ROUTER_NAME.U6} element={<StabilizerManagement />} />
            </Route>

            <Route path={ROUTER_NAME.U7} element={<PrivateRoute screenCode="U7" />}>
              <Route path={ROUTER_NAME.U7} element={<PrimarySlimeProcessing />} />
            </Route>

            <Route path={ROUTER_NAME.U8} element={<PrivateRoute screenCode="U8" />}>
              <Route path={ROUTER_NAME.U8} element={<RebarInspection />} />
            </Route>

            <Route path={ROUTER_NAME.U9} element={<PrivateRoute screenCode="U9" />}>
              <Route path={ROUTER_NAME.U9} element={<SteelPileInspection />} />
            </Route>

            <Route path={ROUTER_NAME.U10} element={<PrivateRoute screenCode="U10" />}>
              <Route path={ROUTER_NAME.U10} element={<HoleWallMeasurement />} />
            </Route>

            <Route path={ROUTER_NAME.U10_1} element={<PrivateRoute screenCode="U10" />}>
              <Route path={ROUTER_NAME.U10_1} element={<HoleWallMeasurement1 />} />
            </Route>

            <Route path={ROUTER_NAME.U11} element={<PrivateRoute screenCode="U11" />}>
              <Route path={ROUTER_NAME.U11} element={<SteelPileRebarManage />} />
            </Route>

            <Route path={ROUTER_NAME.U12} element={<PrivateRoute screenCode="U12" />}>
              <Route path={ROUTER_NAME.U12} element={<SteelPileRebarManageAfter />} />
            </Route>

            <Route path={ROUTER_NAME.U13} element={<PrivateRoute screenCode="U13" />}>
              <Route path={ROUTER_NAME.U13} element={<TomeryErection />} />
            </Route>

            <Route path={ROUTER_NAME.U14} element={<PrivateRoute screenCode="U14" />}>
              <Route path={ROUTER_NAME.U14} element={<U14ConcretePlacement />} />
            </Route>

            <Route path={ROUTER_NAME.U15} element={<PrivateRoute screenCode="U15" />}>
              <Route path={ROUTER_NAME.U15} element={<U15ConcreteTest />} />
            </Route>

            <Route path={ROUTER_NAME.U17} element={<PrivateRoute screenCode="U17" />}>
              <Route path={ROUTER_NAME.U17} element={<U17PostInstallationInspection />} />
            </Route>

            <Route path={ROUTER_NAME.U18} element={<PrivateRoute screenCode="U18" />}>
              <Route path={ROUTER_NAME.U18} element={<U18HardnessTesting />} />
            </Route>

            <Route path={ROUTER_NAME.U19} element={<PrivateRoute />}>
              <Route path={ROUTER_NAME.U19} element={<U19RecordManagement />} />
            </Route>

            <Route path={ROUTER_NAME.U20} element={<PrivateRoute />}>
              <Route path={ROUTER_NAME.U20} element={<U20Note />} />
            </Route>

            <Route path={ROUTER_NAME.U25} element={<PrivateRoute screenCode="U25" />}>
              <Route path={ROUTER_NAME.U25} element={<U25StructuralColumn />} />
            </Route>
            <Route path={ROUTER_NAME.RESET_PASSWORD} element={<ResetPassword />}></Route>
          </Routes>
        </BrowserRouter>
      </Suspense>
    </Fragment>
  );
}

export default AppRouter;
