// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';

import { Navigate, Outlet } from 'react-router';
import { ROUTER_NAME } from './const';
import useLocalStorage from 'use-local-storage';
import { AccessibleSiteDto } from '../api';

function PrivatePileSelectRouter({
  children,
  component,
  ...rest
}: {
  component?: React.ComponentType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}) {  
  const [selectedSite] = useLocalStorage<AccessibleSiteDto>('selectedSite', {});  
  
  if (selectedSite.id) {
    return <Outlet />;
  } else {
    return (
      <Navigate
        to={{
          pathname: ROUTER_NAME.U00,
          search: `next=${window.location.pathname}`,
        }}
        replace
      />
    );
  }
}

PrivatePileSelectRouter.defaultProps = {
  component: undefined,
};

export default PrivatePileSelectRouter;
