import { useState } from "react";
import "./App.css";
import GlobalContext from "./components/PageLayout/GlobalContex";
import AppRouter from "./router/AppRouter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function App() {

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 5 * 1000,
          },
          mutations: {
            onError: (error: any) => {
              console.log(error);
            },
          },
        },
      })
  );

  const [virtualKeyboardEnabled, setVirtualKeyboardEnabled] = useState(true); //VTKB: virtual keyboard

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalContext.Provider
        value={{ virtualKeyboardEnabled, setVirtualKeyboardEnabled }}
      >
        <AppRouter />
      </GlobalContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
