// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useMemo } from 'react';

import { Navigate, Outlet } from 'react-router';
import { ROUTER_NAME } from './const';
import useLocalStorage from 'use-local-storage';
import { AccessibleSiteDto, AvailableScreenDto, PileHistoryDto } from '../api';

function PrivateRoute({
  children,
  component,
  screenCode,
  ...rest
}: {
  component?: React.ComponentType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  screenCode?: string;
}) {  
  const [selectedSite] = useLocalStorage<AccessibleSiteDto>('selectedSite', {});
  const [selectedPile] = useLocalStorage<PileHistoryDto>('selectedPile', {});
  const [listAvailableScreen] = useLocalStorage<AvailableScreenDto[]>('availabelScreens', []);

  const isInListScreen = useMemo(() => {
    if (screenCode) {
      return listAvailableScreen.some((item) => item.code === screenCode);
    }
    return true;
  }, [listAvailableScreen, screenCode]);
  
  if (selectedSite.id && selectedPile.pileId && isInListScreen) {
    
    return <Outlet />;
  } else if (!selectedSite) {
    return (
      <Navigate
        to={{
          pathname: ROUTER_NAME.U00,
          search: `next=${window.location.pathname}`,
        }}
        replace
      />
    );
  } else {
    return (
      <Navigate
        to={{
          pathname: ROUTER_NAME.U01,
          search: `next=${window.location.pathname}`,
        }}
        replace
      />
    );
  }
}

PrivateRoute.defaultProps = {
  component: undefined,
};

export default PrivateRoute;
